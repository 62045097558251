<template>
  <Details
    title="profiles template"
    resource="profileTemplates"
    :base-path="basePath"
    data-provider="$raaDataProvider"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel>
        <template v-slot:heading>template details</template>
        <template v-slot:text>Add a new profile data block template.</template>
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <ProfileTemplateForm
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import ProfileTemplateForm from "@/views/auth/raa-devtools/profiles/templates/ProfileTemplateForm";

  export default {
    name: "ProfileTemplateCreate",
    components: {
      Details,
      DetailsSidePanel,
      ProfileTemplateForm,
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      }
    },
  }
</script>

<style scoped>

</style>
