<template>
  <Form
    :submit="handleSubmit"
    :initialValues="prepareInitialValues(initialValues)"
  >
    <div class="form-narrow">
      <div class="form-row w-1/2">
        <TextField name="name" label="name*" :validate="[required, alphaNumericKey, maxLength(100)]" />
      </div>
      <div class="form-row">
        <TextField name="description" label="description*" :validate="[required, maxLength(400)]" />
      </div>
      <div class="form-row">
        <DropdownMultiselectInput
          name="keys"
          label="data record keys"
          :options="dataKeysOptions"
          :hide-selected="false"
          :hide-tags="true"
        />
      </div>
      <ModalFooter :footer="footer" :tertiary="cancel" />
    </div>
  </Form>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import Form from "@/components/form/Form";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import TextField from "@/components/form/TextField";
  import DropdownMultiselectInput from "@/components/auth/form/DropdownMultiselectInput";

  export default {
    name: "ProfileTemplateForm",
    components: {
      ModalFooter,
      Form,
      TextField,
      DropdownMultiselectInput,
    },
    mixins: [
      ModalNavigation,
      ValidatorMixin,
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        dataKeys: [],
        footer: {
          primaryButton: 'save',
          tertiaryButton: 'cancel'
        },
      }
    },
    computed: {
      dataKeysOptions() {
        return this.dataKeys.map(({ id: key, key: value }) => ({ key, value }))
      },
    },
    methods: {
      prepareInitialValues(initialValues) {
        return {
          ...initialValues,
          keys: initialValues.keys?.map(({ id: key, key: value }) => ({ key, value })) || [],
        }
      },
      handleSubmit({ keys, ...rest }) {
        this.onSubmit({
          ...rest,
          keyIds: keys.map(({ key }) => key),
        });
      },
      fetchDataKeys() {
        this.$raaDataProvider.getList('dataKeys', { size: 999 })
          .then(({content}) => this.dataKeys = content.filter(({deletedAt}) => !deletedAt))
          .catch(error => this.notifyError(error.message));
      },
      cancel(e) {
        e.preventDefault();
        this.close();
      },
    },
    mounted() {
      this.fetchDataKeys();
    },
  }
</script>

<style scoped>

</style>
